import React from 'react'
import {Location, WindowLocation, NavigateFn} from '@reach/router'
import queryString, {ParsedQuery} from 'query-string'

export interface WithLocationProps {
  children?: React.ReactNode
  location?: WindowLocation
  navigate?: NavigateFn
  search?: ParsedQuery
}

const withLocation = (ComponentToWrap: React.FC<WithLocationProps>) => (props: WithLocationProps): JSX.Element => (
  <Location>
    {({location, navigate}): JSX.Element => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
)

export default withLocation
