import React, {useState} from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import withLocation, {WithLocationProps} from '../util/with-location'
import {useAuth} from '../util/use-auth'

import logoCircle from '../images/logo-circle.png'
import logo from '../images/logo.png'

const Nav: React.FC<WithLocationProps> = ({location}) => {
  //const [navbarOpen, setNavbarOpen] = useState(false)

  const auth = useAuth()

  return (
    <nav className="flex flex-wrap items-center justify-between px-2 py-3">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <ul>
          <li>
            <Link to="/" className={location?.pathname === '/' ? 'active' : ''}>
              <img src={logoCircle} alt="Kw" style={{width: '30px'}} className="inline" />
              <img src={logo} alt="Kindawow" style={{width: '200px'}} className="inline" />
            </Link>
          </li>
        </ul>
        {auth && !auth.isAuthenticating && (
          <ul className="right">
            <li>
              {auth.user ? (
                <>
                  <span>You are logged in as {(auth.user as any)?.attributes?.email} </span>{' '}
                  <Link
                    to="/logout/"
                    className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-full">
                    Logout
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/login/"
                    className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-full">
                    Login
                  </Link>{' '}
                  <Link
                    to="/signup/"
                    className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-full">
                    Signup
                  </Link>
                </>
              )}
            </li>
          </ul>
        )}
      </div>
    </nav>
  )
}

Nav.propTypes = {
  location: PropTypes.any,
}

export default withLocation(Nav)
