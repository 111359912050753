import React from 'react'
import PropTypes from 'prop-types'
import Footer from './footer'
import Header from './header'

import '../styles/global.scss'
import '@fontsource/inter'
import '@fontsource/inter/600.css'
import '@fontsource/inter/900.css'
import '@fontsource/fira-mono'

interface Props {
  readonly children: React.ReactNode
}

const Layout: React.FC<Props> = ({children}) => (
  <div className="flex flex-col items-stretch bg-grey-lighter min-h-screen">
    <Header />
    <main className="content container mx-auto flex-1" role="main">
      {children}
    </main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
