import React from 'react'
import {Link} from 'gatsby'

const Footer: React.FC = () => (
  <footer className="container mt-20 px-4 mx-auto flex flex-wrap items-center justify-between">
    <div style={{alignSelf: 'flex-start'}}>© {new Date().getFullYear()} Kinda.wow</div>
    <ul>
      <li>
        <Link to="/terms/">Terms of Service</Link>
      </li>
      <li>
        <Link to="/privacy/">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/projects/">Projects</Link>
      </li>
    </ul>
  </footer>
)

export default Footer
