import React from 'react'
import Nav from './nav'

const Header: React.FC = () => (
  <header>
    <Nav />
  </header>
)

export default Header
